import logo from '../logo.svg';
import '../App.css';
import JavaScript from '../pictures/JavaScriptLogo.png'
import HTML from '../pictures/HTML5Logo.png';
import CSS from '../pictures/CSSLogo.png';
import CPP from '../pictures/CPPLogo.png';
import OpenCV from '../pictures/OpenCVLogo.webp';
import SQL from '../pictures/SQLLogo.png';


function About() {
  return (
    <div className="App" id='about'>
      <h2>About</h2>
      <div className='leftRightContainer'>
        <div className='leftDiv'>
          <img src={logo} height={480} />
          <p className='aboutDes'>
              My name is Darrell Crochet and I enjoy learning new things and improving as a software developer.
              I enjoy making applications that improve my day to day life and strive to provide efficient software.
              Outside of programming I like to spend my time in the gym, playing video games, and pampering my cat.
          </p>
        </div>
        <div id='proficiencyList' className='rightDiv'>
          <li className='proficiencyItems'>
            <img className='proficiencyImageSmall'  src={JavaScript} />
            <br />
            JavaScript
          </li>
          
          <li className='proficiencyItems'>
            <img id='ReactLogo' className="App-logo"  src={logo} alt='React'/>
            <br />
            React
          </li>

          <li className='proficiencyItems'>
            <img id='ReactNativeLogo' className="App-logo" src={logo} alt='React Native'/>
            <br />
            React Native
          </li>

          <li className='proficiencyItems'>
            <img className='proficiencyImageSmall' src={CPP} />
            <br />
            C++
          </li>

          <li className='proficiencyItems'>
            <img className='proficiencyImage'  src={OpenCV} />
          </li>

          <li className='proficiencyItems'>
            <img id='HTMLLogo' className='proficiencyImage' src={HTML} />
          </li>

          <li className='proficiencyItems'>
            <img className='proficiencyImage' src={CSS} />
          </li>

          <li className='proficiencyItems'>
            <img id='SQLLogo' className='proficiencyImage' src={SQL} />
          </li>

        </div>
      </div>
    </div>
  );
}

export default About;
