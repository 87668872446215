import logo from '../logo.svg';
import '../App.css';
import Header from '../components/header.js';

function SudokuSaveState() {
  return (
    <header header className="App-header">
      <Header />
        <h2>Sudoku Save State Privacy Policy</h2>
        <img src={logo} className="App-logo" alt="logo" />
        <p className='aboutDes'>
          No data put into this app leaves the phone in which it is used. 
          No data entered will be sold or accessed by any 3rd party.
          This app dose not engage in any ad targeting, data mining, or any other activity that may use your data nor do we have affiliations with any 3rd parties that do so.
        </p>
      </header>
  );
}

export default SudokuSaveState;
