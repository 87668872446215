import '../App.css';

function Project(props) {
  return (
    <div className="project">
        <h3>{props.title}</h3>
        <h4>{props.type}</h4>
        <img src={props.image} className="" alt="logo" />
        <p className='projectDes'>{props.description}</p>
        <h4 className="Link"><a href={props.link}>Learn More</a></h4>
    </div>
  );
}

export default Project;
