import { Route } from 'react-router-dom';
import '../App.css';

function Header() {
    const logoWidth = 140;
    const logoHeight = logoWidth;
    return ( 
        <div id='header'>
            <a className='header' href='/'><h3 className='header' >Rubber Ducky Software Development</h3></a>
        </div>
     );
}

/*
                <div id="left">
                    <a href='/'><h3>Rubber Ducky Software Development</h3></a>
                </div>
                <div id="right">
                    <div className='rightList'>
                        <h3 className='rightList'> <a href='/#About'>About</a></h3>
                        <h3 className='rightList'>Projects</h3>
                        <h3 className='rightList'>Contact</h3>
                    </div>
                </div>
*/

export default Header;